import { lazy } from "react";

const announcementPagination = lazy(() =>
  import("./AnnouncementPagination").then(({ AnnouncementPagination }) => ({
    default: AnnouncementPagination,
  }))
);
const latestNewsPagination = lazy(() =>
  import("./LatestNewsPagination").then(({ LatestNewsPagination }) => ({
    default: LatestNewsPagination,
  }))
);
const blogPagination = lazy(() =>
  import("views/blog/blogCards/BlogPagination").then(({ BlogPagination }) => ({
    default: BlogPagination,
  }))
);

export { announcementPagination, latestNewsPagination, blogPagination };
