import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { useInitNewsroomArticles } from "./hooks";

import { CardList } from "./CardList";
import { SectionContent } from "./SectionContent";

import { announcementStore, newsStore, blogStore } from "./store";

import { announcementPagination, latestNewsPagination, blogPagination } from "./pagination";

const NewsroomList = () => {
  const list = useInitNewsroomArticles();
  const announcementRef = useRef<HTMLDivElement>(null);
  const latestNewsRef = useRef<HTMLDivElement>(null);
  const blogRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SectionContent
        title={<FormattedMessage id="NEWSROOM_ANNOUNCEMENT_TITLE" />}
        ref={announcementRef}
      >
        <CardList
          articleStore={announcementStore}
          componentPagination={announcementPagination}
          initCards={list.announcement}
          sectionRef={announcementRef}
        />
      </SectionContent>
      <SectionContent title={<FormattedMessage id="NEWSROOM_NEWS_TITLE" />} ref={latestNewsRef}>
        <CardList
          articleStore={newsStore}
          componentPagination={latestNewsPagination}
          initCards={list.latestNews}
          sectionRef={latestNewsRef}
        />
      </SectionContent>
      <SectionContent
        title={<FormattedMessage id="NEWSROOM_AND_BLOG_ARTICLES_TITLE" />}
        ref={blogRef}
      >
        <CardList
          articleStore={blogStore}
          componentPagination={blogPagination}
          initCards={list.blog}
          sectionRef={blogRef}
        />
      </SectionContent>
    </>
  );
};

export default NewsroomList;
