import React, { FC, HTMLProps, RefObject, Suspense, useLayoutEffect } from "react";

import { Cards, PostCard } from "components/PostCard";

import type { announcementPagination, blogPagination, latestNewsPagination } from "../pagination";
import ArticleStore from "views/blog/utils/articleStore";
import { observer } from "mobx-react-lite";

import { ITEMS_PER_PAGE } from "../constants";

const CardContainer: FC<HTMLProps<HTMLDivElement>> = ({ children }) => {
  return (
    <div className="mt-8 grid max-w-max grid-cols-1 items-center justify-items-center gap-x-3 gap-y-5 lg:grid-cols-2 lg:gap-x-7 lg:gap-y-10 xl:mt-14 xl:max-w-none xl:grid-cols-3">
      {children}
    </div>
  );
};

interface CardItemsProps {
  articleStore: ArticleStore;
  initCards: Cards;
}

const CardItems: FC<CardItemsProps> = observer(({ articleStore, initCards }) => {
  useLayoutEffect(() => {
    articleStore.initCards(initCards);
  }, [initCards, articleStore]);

  return (
    <CardContainer>
      {articleStore.cards.map((card) => (
        <PostCard {...card} key={card.href} />
      ))}
    </CardContainer>
  );
});

interface NewsroomPaginationProps {
  articleStore: ArticleStore;
  componentPagination:
    | typeof announcementPagination
    | typeof blogPagination
    | typeof latestNewsPagination;
  onPageChangeEffect?: () => void;
}

const NewsroomPagination: FC<NewsroomPaginationProps> = observer(
  ({ articleStore, componentPagination: CustomPagination, onPageChangeEffect }) => (
    <Suspense>
      <CustomPagination
        currentItem={articleStore.currentItem}
        onPageSelected={(currentItem) => {
          articleStore.setCurrentItem(currentItem);
        }}
        onPageChange={(cards) => {
          articleStore.setCards(cards);
          onPageChangeEffect?.();
        }}
        emptyPaddingItems={0}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </Suspense>
  )
);

interface CardListProps {
  initCards: Cards;
  articleStore: ArticleStore;
  componentPagination:
    | typeof announcementPagination
    | typeof blogPagination
    | typeof latestNewsPagination;
  sectionRef: RefObject<HTMLDivElement>;
}

const CardList: FC<CardListProps> = ({
  initCards,
  articleStore,
  componentPagination,
  sectionRef,
}) => {
  return (
    <>
      <CardItems articleStore={articleStore} initCards={initCards} />
      <NewsroomPagination
        articleStore={articleStore}
        componentPagination={componentPagination}
        onPageChangeEffect={() => {
          sectionRef.current?.scrollIntoView();
        }}
      />
    </>
  );
};
export { CardList };
