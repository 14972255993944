import React, { forwardRef, PropsWithChildren } from "react";

import { Section } from "components/Section";
import { Title } from "components/Title";

import COLOR from "constants/design";

const SectionContent = forwardRef<HTMLDivElement, PropsWithChildren<{ title: JSX.Element }>>(
  ({ title, children }, ref) => (
    <Section background={{ color: COLOR.BACKGROUND_DARK }}>
      <div className="flex flex-col items-center pb-16 pt-14 lg:pt-28 lg:pb-36 xl:items-start">
        <Title ref={ref}>{title}</Title>
        {children}
      </div>
    </Section>
  )
);

export { SectionContent };
