import { Section } from "components/Section";
import React from "react";
import { FormattedMessage } from "react-intl";

import BgStripeUpper from "assets/newsroom_hero_bg_upper.inline.svg";
import BgStripeLower from "assets/newsroom_hero_bg_lower.inline.svg";
import BgBTQIcon from "assets/newsroom_hero_btq_logo.inline.svg";

const GadientBgIcon = ({ className = "" }: { className?: string }) => (
  <div className={`aspect-square w-[clamp(15rem,calc(42vw+5.3rem),51rem)] ${className}`}>
    <div className={`relative h-full w-full`}>
      <div
        className="
          absolute top-1/2 left-1/2 aspect-square h-full
          -translate-x-1/2 -translate-y-1/2 rounded-full
          bg-newsroom-hero-gradient from-[#D9D9D903] to-[#E4E4E466]"
      />
      <div
        className="
          absolute left-1/2 top-1/2 aspect-square h-[clamp(7.5rem,35vw,25rem)] -translate-y-1/2 -translate-x-1/2"
      >
        <div
          className="
            aspect-square h-full rounded-full
            bg-newsroom-hero-gradient from-[#C4C4C42B] to-[#C7C7C775]"
        />
        <BgBTQIcon className="absolute top-1/2 left-1/2 aspect-square h-[70%] -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>
  </div>
);

const StripeBg = ({ className = "" }: { className?: string }) => (
  <div className={`${className}`}>
    <div className="relative h-full min-w-max">
      <BgStripeUpper
        className="absolute top-0 hidden max-h-full w-full md:block"
        preserveAspectRatio="xMaxYMin slice"
      />
      <BgStripeLower
        className="absolute bottom-0 max-h-full w-full"
        preserveAspectRatio="xMinYMax slice"
      />
      <div
        className="
          hidden aspect-[1570/400] h-full md:block"
      >
        {/* keeps upper/lower form touching each other */}
      </div>
    </div>
  </div>
);

const HeroImage = () => (
  <div className="relative h-[15rem] overflow-hidden bg-btq-bg-dark md:h-[25rem]">
    <StripeBg className="absolute h-full w-full" />
    <GadientBgIcon className="absolute top-1/2 right-0 translate-x-1/3 -translate-y-1/2" />
    <div className="absolute bottom-6 md:bottom-28">
      <Section>
        <h1 className="text-2xl font-bold leading-snug sm:text-5xl">
          <FormattedMessage id="NEWSROOM_HERO_TITLE" />
        </h1>
      </Section>
    </div>
  </div>
);

const Hero = () => (
  <div className="bg-btq-bg-dark">
    <div className="hidden xl:block xl:h-[10.5rem]">{/* spacing */}</div>
    <HeroImage />
  </div>
);

export default Hero;
