import { useMemo } from "react";
import useCmsArticlesAsCardsHandler from "./useCmsArticlesAsCardsHandler";

import type { ArticleNode } from "interface/sanityNode";
import type { Cards } from "components/PostCard";

const useHandledArticleCards = (nodes: Array<ArticleNode>) => {
  const { getHandleCards } = useCmsArticlesAsCardsHandler();
  const cards = useMemo<Cards>(() => getHandleCards(nodes), [nodes, getHandleCards]);
  return cards;
};

export default useHandledArticleCards;
