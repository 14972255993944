import { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";

import useHandledArticleCards from "hooks/useHandledArticleCards";

import type { AllSanityArticles } from "interface/sanityNode";
import { Cards } from "components/PostCard";

const initNewsroomArticlesQuery = graphql`
  query GetInitNewsroomArticles {
    allSanityAnnouncement(sort: { fields: articlePublishedTime, order: DESC }, limit: 6) {
      nodes {
        _id
        _rawArticleContent
        _rawArticleSlug
        _rawArticleTitle
        _rawMainImage
        articleAuthor
        articlePublishedTime
      }
    }

    allSanityLatestNews(sort: { fields: articlePublishedTime, order: DESC }, limit: 6) {
      nodes {
        _id
        _rawArticleContent
        _rawArticleSlug
        _rawArticleTitle
        _rawMainImage
        articleAuthor
        articlePublishedTime
      }
    }

    allSanityBlog(sort: { fields: articlePublishedTime, order: DESC }, limit: 6) {
      nodes {
        _id
        _rawArticleContent
        _rawArticleSlug
        _rawArticleTitle
        _rawMainImage
        articleAuthor
        articlePublishedTime
      }
    }
  }
`;

type InitNewsroomArticles = {
  allSanityAnnouncement: AllSanityArticles;
  allSanityLatestNews: AllSanityArticles;
  allSanityBlog: AllSanityArticles;
};

type AllInitArticles = {
  announcement: Cards;
  latestNews: Cards;
  blog: Cards;
};

export const useInitNewsroomArticles = () => {
  const { allSanityAnnouncement, allSanityLatestNews, allSanityBlog } = useStaticQuery(
    initNewsroomArticlesQuery
  ) as InitNewsroomArticles;
  const announcement = useHandledArticleCards(allSanityAnnouncement.nodes);
  const latestNews = useHandledArticleCards(allSanityLatestNews.nodes);
  const blog = useHandledArticleCards(allSanityBlog.nodes);

  const allInitArticles = useMemo<AllInitArticles>(
    () => ({
      announcement,
      latestNews,
      blog,
    }),
    [announcement, latestNews, blog]
  );

  return allInitArticles;
};
